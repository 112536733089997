<template>
<div>
</div>
</template>

<script>
import { useClient, useQuery } from 'villus';
import { gql } from 'graphql-tag';

export default {
  name: 'queryHomeImages',
  watch: {
    homeImage() {
      this.$store.commit('setHomeImage', this.homeImage);
    },
  },
  setup() {
    useClient({
      url: process.env.VUE_APP_GRAPHQL,
      cachePolicy: 'cache-first',
    });

    const context = {
      headers: {
        Authorization: `Bearer ${process.env.VUE_APP_TOKEN}`,
      },
    };

    const queryHomeImages = gql`
      query {
        startseite {
          data {
            attributes {
              Hintergrundbild {
                data {
                          attributes {
                            url
                            formats
                          }
                        }
              }
            }
          }
        }
      }
    `;
    const { data: homeImage } = useQuery({
      query: queryHomeImages,
      context,
    });
    return { homeImage };
  },
};
</script>
