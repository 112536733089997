<template>
<div>
</div>
</template>

<script>
import { useClient, useQuery } from 'villus';
import { gql } from 'graphql-tag';
import { useStore } from 'vuex';
import { computed, reactive } from 'vue';

export default {
  name: 'queryHome',
  watch: {
    home() {
      this.$store.commit('setHome', this.home);
    },
  },
  setup() {
    useClient({
      url: process.env.VUE_APP_GRAPHQL,
      cachePolicy: 'cache-first',
    });

    const store = useStore();

    const context = {
      headers: {
        Authorization: `Bearer ${process.env.VUE_APP_TOKEN}`,
      },
    };

    const variables = reactive({
      lang: computed(() => store.state.lang.currentLang),
    });

    const queryHome = gql`
      query ($lang:I18NLocaleCode!) {
        startseite (locale: $lang){
          data {
            attributes {
              Titel
              Text
              TextRight
              showKursBuchen
              showKontakt
              showPreise
              color
              banner
              Allgemeines
              Seo {
                metaTitle
                metaDescription
              }
            }
          }
        }
      }
    `;
    const { data: home } = useQuery({
      query: queryHome,
      variables,
      context,
    });
    return { home };
  },
};
</script>
