<template>
<div id="page" class="w-full">
  <heroSection
    :color="home?.startseite?.data?.attributes?.color"
  :titel=home?.startseite?.data?.attributes?.Titel
  :subtext=home?.startseite?.data?.attributes?.Text
  :rightText=home?.startseite?.data?.attributes?.TextRight
  :image=homeImage?.startseite?.data?.attributes?.Hintergrundbild?.data[0]?.attributes
  :label1=text.book
  :label2=text.contact
  :showButtonOne="home?.startseite?.data?.attributes?.showKursBuchen"
  :showButtonTwo="home?.startseite?.data?.attributes?.showKontakt"
  :showPriceButton="home?.startseite?.data?.attributes?.showPreise"
  :priceButton="true"
  scrollTo2="Kontakt"
    :runningLine="home?.startseite?.data?.attributes?.banner"
    :runningLineColor="home?.startseite?.data?.attributes?.color"
  :navigate="true" />

  <div class="w-full md:flex md:flex-col md:items-center">

    <div v-if="home?.startseite?.data?.attributes?.Allgemeines" class="w-full h-auto bg-new-black px-8 md:px-20 font-rouben md:max-w-screen-2xl text-new-white my-5 pt-24 md:pt-5 flex flex-col md:flex-row justify-center items-center">
      <div v-html="convertMarkdown(home?.startseite?.data?.attributes?.Allgemeines)" class="md:w-full markdown whitespace-pre-line text-base mb-3 leading-7 mt-3 lg:mt-0 text-left" />
    </div>

  </div>

  <div class="w-full md:flex md:flex-col md:items-center">
    <footerComponent />
  </div>

  <queryHome />
  <queryHomeImage />
</div>
</template>

<script>
// Components
import footerComponent from '../components/footer.vue';
import heroSection from '../components/heroSection.vue';

// Mixins
import showHide from '../mixins/showHide.vue';
import convertMarkdown from '../mixins/convertMarkdown.vue';

// GraphQL
import queryHome from '../graphql/queryHome.vue';
import queryHomeImage from '../graphql/queryHomeImage.vue';

export default {
  name: 'Home',
  components: {
    queryHome,
    queryHomeImage,
    heroSection,
    footerComponent,
  },
  mixins: [showHide, convertMarkdown],

  data() {
    return {
      currentNewsIndex: 0,
    };
  },

  computed: {
    currentLang() {
      return this.$store.state.lang.currentLang;
    },
    home() {
      return this.$store.state.data.home;
    },
    mobile() {
      return this.$store.state.data.mobile;
    },
    homeImage() {
      return this.$store.state.data.homeImage;
    },
    newsPreview() {
      return this.$store.state.data.newsPreview;
    },
    text() {
      return this.$store.state.lang.home[this.$store.state.lang.currentLang];
    },
    sonstiges() {
      return this.$store.state.data.sonstiges;
    },
  },

  mounted() {
    console.log('mounted');
    this.$nextTick(() => {
      setTimeout(() => {
        window.scrollTo({ top: 0, left: 0 });
      }, 500);
    });
  },

  watch: {
    home() {
      const seo = this.home?.startseite?.data?.attributes?.Seo;
      document.title = seo?.metaTitle;
      document.querySelector('meta[name="description"]').setAttribute('content', seo?.metaDescription);
    },
  },

  methods: {

    truncate(text) {
      if (this.mobile) {
        return (text?.length > 150) ? `${text.substr(0, 150 - 1)}. . .` : text;
      }
      return (text?.length > 450) ? `${text.substr(0, 450 - 1)}. . .` : text;
    },

    previous() {
      if (this.currentNewsIndex > 0) {
        // eslint-disable-next-line no-plusplus
        this.currentNewsIndex--;
      }
    },

    next() {
      if (this.currentNewsIndex < this.newsPreview?.news?.data?.length - 1) {
        // eslint-disable-next-line no-plusplus
        this.currentNewsIndex++;
      }
    },
  },
};
</script>

<style>
@media (min-width: 768px) {
  .column {
    column-count: 2;
    column-gap: 5rem;
    overflow: hidden;
  }
}
</style>
